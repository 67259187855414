/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import "~@angular/cdk/overlay-prebuilt.css";

.slick-dots {
    bottom: -45px !important;
    li {
        button {
            &:before {
                content: "" !important;
                background-color: #fff;
                position: absolute;
                display: inline-block !important;
                width: 1rem !important;
                height: 0.375rem !important;
                margin: 0 0.3rem !important;
                padding: 0 !important;
                transition:
                    width 0.2s ease-in-out,
                    background-color 0.2s ease-in-out;
                border: 0;
                border-radius: 50rem;
            }
        }
    }
}

.ks-modal-gallery-backdrop {
    background: #000 !important;
    opacity: 0.85 !important;
}
.ks-modal-gallery-panel {
    z-index: 90000 !important;
}
