/*
 * Finder | Directory & Listings Bootstrap Template
 * Copyright 2022 Createx Studio
 * Theme styles
 */

// User variables
@import "user-variables";

// Configuration & utilities
@import "variables";
@import "utilities";

// Bootstrap

@import "./node_modules/bootstrap/scss/bootstrap";

// Layout & components
@import "reboot";
@import "components";

// User custom styles
@import "user";

// custom styles
@import "custom";

@import "~@angular/cdk/overlay-prebuilt.css";
.ks-modal-gallery-backdrop {
    background: #000 !important;
    opacity: 0.85 !important;
}
.ks-modal-gallery-panel {
    z-index: 90000 !important;
}
