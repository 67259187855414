@charset "UTF-8";

/* Reviews Rating */
.stars {
  font-size: 25px;
  color: $warning;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.vertical-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(#09080d, 0.6);
  z-index: 1030;
  display: none;

  &.show {
    display: block;
  }
}

.swiper-pagination {
  .swiper-pagination-bullet {
    width: 1rem;
    height: 0.375rem;
    margin: 0 0.3rem;
    border-radius: 5px;
    padding: 0px;
    transition: all 0.5s ease;
  }

  .swiper-pagination-bullet-active {
    width: 2rem;
    background-color: $white;
  }
}

swiper {
  >.swiper {
    &.s-wrapper {
      .swiper-pagination {
        .swiper-pagination-handle {
          margin: 0px !important;
          padding: 3px !important;
        }
      }
    }
  }
}

.swiper-button-next,
.swiper-button-prev {
  width: 2.25rem !important;
  height: 2.25rem !important;
  background-color: $white;
  box-shadow: $box-shadow-sm;
  border-radius: 50%;

  &::after {
    font-family: "finder-icons" !important;
    color: $gray-800;
    font-size: .75rem !important;

  }
}

.custom-swiper {
  .swiper-wrapper {
    margin-bottom: 60px;
  }
}

.swiper-button-prev {
  &::after {
    content: "\e951" !important;
  }
}

.swiper-button-next {
  &::after {
    content: "\e952" !important;
  }
}


.dropdown-menu {
  &.dropdown-menu-end {
    left: auto !important;
    right: 0px;
  }
}

.tooltip-inner {
  max-width: 500px !important;
}

@media (max-width: 767.98px) {
  .card-img-size {
    height: 179px !important;
    object-fit: cover;
  }
}

.swiper-pagination {
  .swiper-pagination-bullet {
    background-color: $white;
  }
}

.ngx-slider-limit {
  &.ngx-slider-floor {
    display: none !important;
  }
}

.ngx-slider-limit {
  &.ngx-slider-ceil {
    display: none !important;
  }
}

/* video modal */
.video-model {
  border: none;
  background-color: transparent;

  iframe {
    height: 100% !important;
    width: 100%;
  }
}

/* simplebar-scroll-show */
.simplebar-scroll-show {
  .simplebar-scrollbar {
    left: -1px;

    &::before {
      opacity: 1 !important;
      background-color: $gray-400;
      width: 4px;
    }
  }
}

.simplebar-track.simplebar-vertical {
  background: rgba($white, 0.2);
  z-index: -1;
  width: 5px !important;
}


/* Custom Css */
.ngx-slider {
  .ngx-slider-pointer {
    top: 50% !important;
    width: 1.125rem !important;
    height: 1.125rem !important;
    margin-top: -0.511875rem !important;
    border: 0 !important;
    border-radius: 50% !important;
    background-color: $primary !important;
    box-shadow: none !important;

    &::after {
      position: absolute !important;
      left: 50% !important;
      top: 50% !important;
      width: 0.6435rem !important;
      height: 0.6435rem !important;
      margin-top: -0.32175rem !important;
      margin-left: -0.32175rem !important;
      border-radius: 50% !important;
      background-color: $white !important;
      content: "" !important;
    }
  }

  .ngx-slider-bar {
    background: rgba(255, 255, 255, .2) !important;
  }

  .ngx-slider-selection {
    background-color: $primary !important;
  }

  .ngx-slider-bubble {
    padding: 0.25rem 0.5rem !important;
    border: 1px solid $gray-300;
    border-radius: 50rem;
    background-color: $white;
    color: $gray-800 !important;
    font-size: .75rem !important;
    line-height: 1.2;
    box-shadow: $box-shadow-sm;
  }

  &.animate {
    .ngx-slider-bubble {
      &.ngx-slider-limit {
        display: none;
      }
    }
  }
}

.arrow-none {
  &::after {
    display: none;
  }
}

.progress {
  &.progress-light {
    .progress {
      background-color: transparent;
    }
  }
}

.show {
  >.btn-light {
    &.dropdown-toggle {
      color: rgba($white, 0.7);
    }
  }
}

.pagination-hide {
  margin-bottom: 0px;

  .swiper-wrapper {
    padding-bottom: 0px;
  }

  .swiper-pagination {
    display: none;
  }

  @media (max-width: 991.98px) {
    margin-bottom: 20px;

    .swiper-pagination {
      display: block;
    }

    .swiper-wrapper {
      padding-bottom: 45px;
    }
  }
}

.form-check {
  .form-check-label {
    cursor: pointer;
  }
}

.form-select,
.cursor-pointer {
  cursor: pointer;
}

.swiper-container {
  overflow: hidden;
}

swiper {
  height: 100%;
}


.slide {
  height: 100%;
  width: 100%;
  background-position: center;
  display: grid;
}


.slickSwiper {
  .slick-prev {
    &::before {
      content: $fi-chevron-left !important;
      font-family: 'finder-icons' !important;
      color: #454056;
      position: absolute;
      left: -20px;
      top: -23px;
      width: 2.25rem;
      height: 2.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -1.125rem;
      transition: all .3s ease-in-out;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, .04);
      color: #fff;
      font-size: .75rem;
      line-height: 2.25rem -0.25rem;
      text-align: center;
      border: 0;
      box-shadow: 0 0.125rem 0.125rem -0.125rem rgba(31, 27, 45, .08), 0 0.25rem 0.75rem rgba(31, 27, 45, .08);
      z-index: 10;
    }
  }

  .slick-next {
    &::before {
      content: $fi-chevron-right !important;
      font-family: 'finder-icons' !important;
      color: #454056;
      position: absolute;
      right: -1px;
      width: 2.25rem;
      top: -23px;
      height: 2.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -1.125rem;
      transition: all .3s ease-in-out;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, .04);
      color: #fff;
      font-size: .75rem;
      line-height: 2.25rem -0.25rem;
      text-align: center;
      border: 0;
      box-shadow: 0 0.125rem 0.125rem -0.125rem rgba(31, 27, 45, .08), 0 0.25rem 0.75rem rgba(31, 27, 45, .08);
      z-index: 10;
    }
  }
}


// ----

.tns-carousel-wrapper {
  .slick-slide {
    width: auto;
    // height: 250px;
  }

  .slick-slider{
    .slick-next {
      right: -29px;
      z-index: 1;
    }

    .slick-prev {
      left: -28px;
      z-index: 1;
    }
  }
}